var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "helpCenter" }, [
    _c(
      "div",
      { staticClass: "head" },
      [
        _c("van-search", {
          attrs: {
            "show-action": "",
            maxlength: "30",
            autofocus: true,
            placeholder: "搜索希望查找的关键词"
          },
          on: { cancel: _vm.onCancel, input: _vm.onInput, clear: _vm.onClear },
          model: {
            value: _vm.keyword,
            callback: function($$v) {
              _vm.keyword = $$v
            },
            expression: "keyword"
          }
        })
      ],
      1
    ),
    _vm.loading2
      ? _c("div", { staticClass: "load" }, [
          _c("img", {
            staticClass: "loading",
            attrs: { src: require("../../assets/images/loading.png") }
          }),
          _c("div", { staticClass: "tip" }, [_vm._v("搜索中")])
        ])
      : _vm._e(),
    _vm.question.length
      ? _c(
          "div",
          { staticClass: "body" },
          [
            _c(
              "van-list",
              {
                attrs: {
                  finished: _vm.finished,
                  "immediate-check": false,
                  "finished-text": "没有更多了"
                },
                on: { load: _vm.onLoad },
                model: {
                  value: _vm.loading,
                  callback: function($$v) {
                    _vm.loading = $$v
                  },
                  expression: "loading"
                }
              },
              _vm._l(_vm.question, function(item, index) {
                return _c("van-cell", {
                  key: index,
                  attrs: { title: item.title, "is-link": "" },
                  on: {
                    click: function($event) {
                      return _vm.goDetail(item)
                    }
                  }
                })
              }),
              1
            )
          ],
          1
        )
      : _c("div", { staticClass: "body2" }, [
          _c("img", {
            staticClass: "nullimg",
            attrs: { src: require("../../assets/images/null.png") }
          }),
          _c("div", { staticClass: "nullworld" }, [_vm._v("未搜索到相关内容")])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }