<!--
 * @Author: soofly wy463554101@163.com
 * @Date: 2022-07-18 11:16:01
 * @LastEditors: soofly wy463554101@163.com
 * @LastEditTime: 2022-08-03 12:06:22
 * @FilePath: /help.ringle.com/src/views/mobile/search/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="helpCenter">
    <div class="head">
      <van-search
        v-model="keyword"
        show-action
        maxlength="30"
        :autofocus="true"
        placeholder="搜索希望查找的关键词"
        @cancel="onCancel"
        @input="onInput"
        @clear="onClear"
      />
    </div>
    <div class="load" v-if="loading2">
      <img src="../../assets/images/loading.png" class="loading">
      <div class="tip">搜索中</div>
    </div>
    <div class="body" v-if="question.length">
      <van-list
          v-model="loading"
          :finished="finished"
          :immediate-check="false"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <van-cell :title="item.title" is-link  v-for="(item,index) in question" :key="index" @click="goDetail(item)"/>
      </van-list>
    </div>
    
    <div class="body2" v-else>
      <img src="../../assets/images/null.png" class="nullimg">
      <div class="nullworld">未搜索到相关内容</div>
    </div>
  </div>
</template>

<script>
import { searchQue } from "../../api/index";
export default {
  components: {},
  data() {
    return {
      question: [], //问题列表
      keyword: "",
      loading2:false,
      anchor:null,
      loading:false,
      finished:false,
    };
  },
  beforeRouteEnter(to,from,next){
      if(from.name=='mdetail'){
          to.meta.isFress=true
      }
      next()
  },
  activated() {
      if(this.$route.meta.isFress){
          this.$route.meta.isFress=false
      }else{
        this.onClear()
      }
  },
  created() {},
  mounted() {},
  methods: {
    onCancel(){
      this.$router.back()
    },
    onInput(e){
      this.anchor=null
      this.question=[]
      this.loading2=true
      this.loading=false
      this.finished=false
      this.keyWord=e
      if(e){
        this.searchQue()
      }else{
        this.loading2=false
      }
    },
    onClear(){
      this.keyword=''
      this.question=[]
      this.loading=false
      this.finished=false
      this.anchor=null
    },
    //搜索结果
    searchQue() {
      const parmas = {
        keyWord:this.keyWord,
        'paging.anchor':this.anchor,
        'paging.count':10,
        'paging.direction':'DOWN',
        'platformId':'APP'
      }
      searchQue(parmas).then((res) => {
        if(res.code==200){
          if(res.result.items){
            this.question=this.question.concat(res.result.items)
          }
          this.loading2=false
          // 加载状态结束
          this.loading = false;
          if(res.result.next){
            this.anchor=res.result.next.anchor
          }else{
            // 数据全部加载完成
            this.finished = true;
          }
        }
      });
    },
    //跳转问题详情
    goDetail(item) {
      this.$router.push({path: "/mdetail",query: {id: item.id }});
    },
    onLoad(){
      this.searchQue()
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .van-search__content{
  border-radius: 104px;
}
.head{
  position: sticky;
  top: 0;
  z-index: 1000;
  border-bottom: 1px solid #ebedf0;
}
.body2{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 300px);
    .nullimg{
      width: 365px;
      height: 198px;
    }
    .nullworld{
      font-size: 27px;
      color: #999999;
    }
}
.body{
  ::v-deep .van-cell{
    font-size: 31px;
    color:#222222;
    line-height: 115px;
    .van-cell__right-icon{
      line-height: 115px;
    }
    .van-cell__title{
      overflow: hidden;//溢出隐藏
      white-space: nowrap;//禁止换行
      text-overflow: ellipsis;
    }
  }
}
.load{
  padding-left: 35px;
  margin-top: 21px;
  display: flex;
  flex-direction: row;
  align-items: center;
  .loading{
    width: 42px;
    height: 42px;
    animation:gogogo 2s infinite linear;
  }
  .tip{
    color: #999999;
    font-size: 25px;
    padding-left: 10px;
  }
}

@keyframes gogogo {
    0%{
        -webkit-transform: rotate(0deg);
    }
    50%{
        -webkit-transform: rotate(180deg);
    }
    100%{
        -webkit-transform: rotate(360deg);
    }
 
}
</style>
